var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseCard', {
    staticClass: "px-0 mt-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.goToCommunicationTemplateList
          }
        }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _vm._v(" Kommunikasjonsmaler ")];
      },
      proxy: true
    }])
  }, [_c('v-row', _vm._l(_vm.templateCard, function (templateCardItem, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-0 px-2",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', {
      attrs: {
        "data-cy": "templateCardItemTitle-".concat(index)
      }
    }, [_vm._v(" " + _vm._s(templateCardItem.title) + " ")]), _c('v-list-item-title', {
      attrs: {
        "data-cy": "templateCardItemValue-".concat(index)
      }
    }, [_vm._v(" " + _vm._s(templateCardItem.value) + " ")])], 1)], 1)], 1);
  }), 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    attrs: {
      "data-cy": "templateContent"
    }
  }, [_vm.templateContent ? _c('viewer', {
    attrs: {
      "initialValue": _vm.templateContent
    }
  }) : _c('span', {
    staticClass: "font-italic"
  }, [_vm._v(" Kommunikasjonsmalen har ikke noe innhold ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }