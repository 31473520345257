
import { api } from "@/api/api";
import BaseCard from "@/components/shared/BaseCard.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { useRouter, useRoute } from "@/shared/useHelpers";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { Viewer } from "@toast-ui/vue-editor";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import "@/shared/styles/toastui-editor.scss";

interface TemplateCardItem {
  title: string;
  value?: string;
}

export default defineComponent({
  name: "CommunicationTemplatePage",
  components: {
    BaseCard,
    Viewer,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const templateId = parseInt(route.params.communicationTemplateId);
    const templateCard = ref<TemplateCardItem[]>();
    const templateContent = ref<string>();

    const goToCommunicationTemplateList = () => {
      router.push({
        name: AdminRouteNames.CommunicationTemplateList,
      });
    };

    const getDepartmentNameById = async (id: number) => {
      const departments = await (await api.organization.getDepartmentsAsync()).data;
      return departments.find((org) => org.id === id)?.name ?? "Ukjent";
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const templateResponse = await (
          await api.communicationtemplate.getCommunicationTemplateByIdAsync(templateId)
        ).data;
        templateCard.value = [
          {
            title: "Malgruppe",
            value: templateResponse.communicationTemplateGroupName ?? "-",
          },
          {
            title: "Eier",
            value: templateResponse.communicationTemplateGroupOrganizationId
              ? await getDepartmentNameById(templateResponse.communicationTemplateGroupOrganizationId)
              : "-",
          },
          {
            title: "Malnavn",
            value: templateResponse.name ?? "-",
          },
          {
            title: "Tittel",
            value: templateResponse.subject ?? "-",
          },
          {
            title: "Laget av",
            value: templateResponse.insertedByUserFullName ?? "-",
          },
          {
            title: "Sist oppdatert",
            value: formatRelative(templateResponse.updated ?? ""),
          },
        ];
        templateContent.value = templateResponse.content ?? undefined;
      });
    });

    return {
      templateCard,
      templateContent,
      goToCommunicationTemplateList,
    };
  },
});
